import { FormConfig } from '@ibe/components';
import { ApiTravelerType } from '@ibe/api';
import Keys from '@/Translations/generated/en/traveller-builder.json.keys';
import { TFunction } from 'i18next';
import dayjs from 'dayjs';

const randomNumber = (): number => Math.floor(Math.random() * 5);

export const getTravelerMockData = (): Record<string, unknown> => {
  return {
    salutation: 'MR',
    firstname: ['Firstname1', 'Firstname2', 'Firstname3', 'Firstname4', 'Firstname5'][
      randomNumber()
    ],
    lastname: ['Lastname1', 'Lastname2', 'Lastname3', 'Lastname4', 'Lastname5'][randomNumber()],
    birthDate: ['1960-06-26', '1970-07-27', '1980-08-28', '1990-09-29', '2000-10-30'][
      randomNumber()
    ],
    street: ['Street1', 'Street2', 'Street3', 'Street4', 'Street5'][randomNumber()],
    houseNumber: ['10', '20', '30', '30', '50'][randomNumber()],
    city: ['City1', 'City2', 'City3', 'City4', 'City5'][randomNumber()],
    zipCode: ['12345', '23456', '34567', '45678', '56789'][randomNumber()],
    country: 'DE',
    phone: ['93874054', '89605456', '23409857', '689098345', '0749385765'][randomNumber()],
    email: [
      'test1@testing.net',
      'test2@testing.net',
      'test3@testing.net',
      'test4@testing.net',
      'test5@testing.net'
    ][randomNumber()]
  };
};

export const ADULT_START_AGE = 18;
export const CHILD_MAX_AGE = 2;

export interface FormItemsProps {
  birthDate: string;
  city: string;
  country: string;
  email: string;
  firstname: string;
  id: string;
  lastname: string;
  phone: string;
  salutation: string;
  street: string;
  zipCode: string;
  [a: string]: string;
}

const travellerFormConfig = (
  travelerType: ApiTravelerType,
  t: TFunction,
  initialValues?: { [key: string]: string },
  dateFormat?: string,
  isMobileView?: boolean,
  isAdult?: boolean,
  startDate?: string
): FormConfig => {
  const childrenYear = dayjs()
    .subtract(parseInt(initialValues?.age || '0'), 'years')
    .format('YYYY');
  const maxDate = isAdult
    ? dayjs(startDate).subtract(ADULT_START_AGE, 'years').toDate()
    : dayjs(startDate).year(parseInt(childrenYear)).add(CHILD_MAX_AGE, 'years').toDate();
  const initialDateValue = isAdult
    ? dayjs(startDate).subtract(ADULT_START_AGE, 'years').format('YYYY-MM-DD')
    : dayjs().year(parseInt(childrenYear)).format('YYYY-MM-DD');

  return {
    rows: [
      {
        key: 'row1',
        elements: [
          {
            tag: 'select',
            key: 'salutation',
            placeholder: t(Keys.salutation),
            externalDataOptions: 'salutations',
            rowGrid: {
              md: 6
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            }
          },
          {
            tag: 'input',
            key: 'firstname',
            placeholder: t(Keys.firstname),
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                {
                  type: 'onlyNumbersNotAllowed',
                  customMessage: t(Keys.onlyNumbersNotAllowed)
                },
                {
                  type: 'onlySpacesNotAllowed',
                  customMessage: t(Keys.onlySpacesNotAllowed)
                },
                {
                  type: 'customRegExValidation',
                  customMessage: t(Keys.enterValidName),
                  parameter: /^(?!.*[!@#$%^&*()_+=\[\]{};:"\\|,.<>?/])(?:[A-Za-zÀ-ÖØ-öø-ÿ'-]+|\b\d+\b)(?:\s(?:[A-Za-zÀ-ÖØ-öø-ÿ'-]+|\b\d+\b))*$/
                }
              ]
            },
            rowGrid: {
              md: 6
            }
          },
          {
            tag: 'input',
            key: 'lastname',
            placeholder: t(Keys.lastname),
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                {
                  type: 'onlyNumbersNotAllowed',
                  customMessage: t(Keys.onlyNumbersNotAllowed)
                },
                {
                  type: 'onlySpacesNotAllowed',
                  customMessage: t(Keys.onlySpacesNotAllowed)
                },
                {
                  type: 'customRegExValidation',
                  customMessage: t(Keys.enterValidName),
                  parameter: /^(?!.*[!@#$%^&*()_+=\[\]{};:"\\|,.<>?/])(?:[A-Za-zÀ-ÖØ-öø-ÿ'-]+|\b\d+\b)(?:\s(?:[A-Za-zÀ-ÖØ-öø-ÿ'-]+|\b\d+\b))*$/
                }
              ]
            },
            rowGrid: {
              md: 12
            }
          },
          {
            tag: 'input',
            key: 'street',
            placeholder: t(Keys.street_houseNumber),
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              md: 12
            }
          },
          {
            tag: 'input',
            key: 'zipCode',
            placeholder: t(Keys.zipCode),
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              md: 6
            }
          },
          {
            tag: 'input',
            key: 'city',
            placeholder: t(Keys.city),
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              md: 6
            }
          },
          {
            tag: 'select',
            key: 'country',
            placeholder: t(Keys.country),
            externalDataOptions: 'countries',
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              md: 12
            }
          },
          {
            tag: 'datePicker',
            key: 'birthDate',
            placeholder: t(Keys.dateOfBirth) + ' (' + dateFormat + ')',
            rowGrid: {
              md: 6
            },
            withKeyboardSupport: true,
            showInModal: isMobileView,
            modalLabel: t(Keys.modalLabel),
            // maxDate,
            // noInitialValueInput: true,
            // initialValue: initialDateValue,
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                ...(travelerType === ApiTravelerType.ADULT
                  ? [
                      {
                        type: 'isOlderThanAgeOnDateString',
                        customMessage: t(Keys.errorInvalidAdult)
                      }
                    ]
                  : travelerType === ApiTravelerType.CHILD
                  ? [
                      {
                        type: 'isYoungerThanAgeOnDateString',
                        customMessage: t(Keys.errorInvalidAdult)
                      }
                    ]
                  : [])
              ]
            }
          },
          {
            tag: 'input',
            key: 'phone',
            placeholder: t(Keys.phone),
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            },
            rowGrid: {
              md: 6
            }
          },
          {
            tag: 'input',
            key: 'email',
            placeholder: t(Keys.email),
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                {
                  type: 'email',
                  customMessage: t(Keys.errorEmail)
                }
              ]
            },
            rowGrid: {
              md: 12
            }
          }
        ]
      }
    ]
  };
};

export default travellerFormConfig;
