/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-02-07 08:14:45 */

const Keys = {
  tripsFound: 'tripsFound',
  hotel: 'hotel',
  hotel_plural: 'hotel_plural',
  from: 'from',
  destination: 'destination',
  destination_plural: 'destination_plural',
  noPackagesFoundInformation: 'noPackagesFoundInformation',
  onContinueLoadingText: 'onContinueLoadingText',
  allDestinations: 'allDestinations'
}; export default Keys;