import { FormConfig } from '@ibe/components';
import { ApiTravelerType } from '@ibe/api';
import Keys from '@/Translations/generated/en/traveller-builder.json.keys';
import { TFunction } from 'i18next';
import dayjs from 'dayjs';
import { ADULT_START_AGE, CHILD_MAX_AGE } from '@/Config/travellerFormConfig';

const randomNumber = (): number => Math.floor(Math.random() * 5);

export const getTravelerMockDataSmall = (): Record<string, unknown> => {
  return {
    salutation: 'MR',
    firstname: ['Firstname1', 'Firstname2', 'Firstname3', 'Firstname4', 'Firstname5'][
      randomNumber()
    ],
    lastname: ['Lastname1', 'Lastname2', 'Lastname3', 'Lastname4', 'Lastname5'][randomNumber()],
    birthDate: ['1960-06-26', '1970-07-27', '1980-08-28', '1990-09-29', '2000-10-30'][
      randomNumber()
    ]
  };
};
const travellerFormConfigSmall = (
  travelerType: ApiTravelerType,
  t: TFunction,
  initialValues?: { [key: string]: string },
  dateFormat?: string,
  isMobileView?: boolean,
  isAdult?: boolean,
  startDate?: string
): FormConfig => {
  const childrenYear = dayjs()
    .subtract(parseInt(initialValues?.age || '0'), 'years')
    .format('YYYY');
  const maxDate = isAdult
    ? dayjs(startDate).subtract(ADULT_START_AGE, 'years').toDate()
    : dayjs(startDate).year(parseInt(childrenYear)).add(CHILD_MAX_AGE, 'years').toDate();
  const initialDateValue = isAdult
    ? dayjs(startDate).subtract(ADULT_START_AGE, 'years').format('YYYY-MM-DD')
    : dayjs().year(parseInt(childrenYear)).format('YYYY-MM-DD');

  return {
    rows: [
      {
        key: 'row1',
        elements: [
          {
            tag: 'select',
            key: 'salutation',
            placeholder: t(Keys.salutation),
            externalDataOptions: 'salutations',
            rowGrid: {
              md: 6
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                }
              ]
            }
          },
          {
            tag: 'input',
            key: 'firstname',
            placeholder: t(Keys.firstname),
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                {
                  type: 'onlyNumbersNotAllowed',
                  customMessage: t(Keys.onlyNumbersNotAllowed)
                },
                {
                  type: 'onlySpacesNotAllowed',
                  customMessage: t(Keys.onlySpacesNotAllowed)
                },
                {
                  type: 'customRegExValidation',
                  customMessage: t(Keys.enterValidName),
                  parameter: /^(?!.*[!@#$%^&*()_+=\[\]{};:"\\|,.<>?/])(?:[A-Za-zÀ-ÖØ-öø-ÿ'-]+|\b\d+\b)(?:\s(?:[A-Za-zÀ-ÖØ-öø-ÿ'-]+|\b\d+\b))*$/
                }
              ]
            },
            rowGrid: {
              md: 6
            }
          },
          {
            tag: 'input',
            key: 'lastname',
            placeholder: t(Keys.lastname),
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                {
                  type: 'onlyNumbersNotAllowed',
                  customMessage: t(Keys.onlyNumbersNotAllowed)
                },
                {
                  type: 'onlySpacesNotAllowed',
                  customMessage: t(Keys.onlySpacesNotAllowed)
                },
                {
                  type: 'customRegExValidation',
                  customMessage: t(Keys.enterValidName),
                  parameter: /^(?!.*[!@#$%^&*()_+=\[\]{};:"\\|,.<>?/])(?:[A-Za-zÀ-ÖØ-öø-ÿ'-]+|\b\d+\b)(?:\s(?:[A-Za-zÀ-ÖØ-öø-ÿ'-]+|\b\d+\b))*$/
                }
              ]
            },
            rowGrid: {
              md: 6
            }
          },
          {
            tag: 'datePicker',
            key: 'birthDate',
            placeholder: t(Keys.dateOfBirth),
            withKeyboardSupport: true,
            showInModal: isMobileView,
            modalLabel: t(Keys.modalLabel),
            // maxDate,
            // noInitialValueInput: true,
            // initialValue: initialDateValue,
            rowGrid: {
              md: 6
            },
            validations: {
              schema: 'string',
              types: [
                {
                  type: 'required',
                  customMessage: t(Keys.inputRequired)
                },
                ...(travelerType === ApiTravelerType.ADULT
                  ? [
                      {
                        type: 'isOlderThanAgeOnDateString',
                        customMessage: t(Keys.errorInvalidAdult)
                      }
                    ]
                  : travelerType === ApiTravelerType.CHILD
                  ? [
                      {
                        type: 'isYoungerThanAgeOnDateString',
                        customMessage: t(Keys.errorInvalidAdult)
                      }
                    ]
                  : [])
              ]
            }
          }
        ]
      }
    ]
  };
};

export default travellerFormConfigSmall;
