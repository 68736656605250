/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-02-07 08:14:45 */

const Keys = {
  passwordChangeFailed: 'passwordChangeFailed',
  passwordChangeSuccess: 'passwordChangeSuccess',
  passwordPolicyInformation: 'passwordPolicyInformation',
  minLength: 'minLength',
  maxLength: 'maxLength',
  excludeWhitespaces: 'excludeWhitespaces',
  minMatchingCharRules: 'minMatchingCharRules',
  minUppercaseChar: 'minUppercaseChar',
  minLowercaseChar: 'minLowercaseChar',
  minNumericChar: 'minNumericChar',
  minSpecialChar: 'minSpecialChar',
  header: 'header'
}; export default Keys;