import { ApiBestPricePackageModel, ApiErrorResponseFromJSON, ApiHotel } from '@ibe/api';
import { removeTrailingSlashes } from '@ibe/services';

class HotelService {
  private _apiUrl: string;
  private _language: string;
  private CMS_HOTEL_ENDPOINT_PATH = '/cms/hotel';

  constructor() {}

  set apiUrl(value: string) {
    this._apiUrl = removeTrailingSlashes(value);
  }

  set language(value: string) {
    this._language = value;
  }

  async fetchHotel(code: string): Promise<ApiBestPricePackageModel> {
    try {
      const response = await fetch(this._apiUrl + this.CMS_HOTEL_ENDPOINT_PATH + '?code=' + code, {
        headers: {
          'Accept-Language': this._language
        }
      });
      return (await response.json()) as ApiBestPricePackageModel;
    } catch (e) {
      throw ApiErrorResponseFromJSON(e);
    }
  }
}

export default HotelService;
