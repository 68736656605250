import { useContext } from 'react';
import { useLocalStore } from 'mobx-react';
import HotelService from '../Services/HotelService';
import HotelContext from '../Contexts/HotelContext';
import { useAppService, useConfig } from '@ibe/components';
import { ConfigModel } from '@ibe/services';

function useHotel(): HotelService {
  const context = useContext(HotelContext);
  const hotelService: HotelService = useLocalStore<HotelService>(() => context);
  const config: ConfigModel = useConfig();
  const appService = useAppService();
  hotelService.apiUrl = config.apiUrl;
  hotelService.language = appService.lang;

  return hotelService;
}

export default useHotel;
