/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-02-07 08:14:45 */

const Keys = {
  noSearchChangeMessage: 'noSearchChangeMessage',
  backToList: 'backToList',
  bookingErrorModalBody: 'bookingErrorModalBody',
  bookingErrorModalButton: 'bookingErrorModalButton',
  onContinueLoadingText: 'onContinueLoadingText',
  adjustNumberTraveler: 'adjustNumberTraveler',
  searchYellowSunFlightOnly: 'searchYellowSunFlightOnly',
  maxOccupancyModalTitle: 'maxOccupancyModalTitle',
  maxOccupancyModalDescription: 'maxOccupancyModalDescription'
}; export default Keys;