import React from 'react';
import { Row, Col, CustomInput } from 'reactstrap';
import { Element as ScrollTo } from 'react-scroll';
import Keys from '@/Translations/generated/en/Checkout.json.keys';
import useTranslationMGL from '@/Util/useTranslationMgl';
import { sanitize } from '@ibe/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '@ibe/components';
import KeysB2B from '@/Translations/generated/en/Checkout-B2B.json.keys';

export interface TACSectionProps {
  showFirstSlotError?: boolean;
  showSecondSlotError?: boolean;
  isFirstSlotChecked?: boolean;
  isSecondSlotChecked?: boolean;
  onFirstSlotCheckboxChange?: () => void;
  onSecondSlotCheckboxChange?: () => void;
}

const TACSection = function TACSection(props: TACSectionProps): JSX.Element {
  const {
    showFirstSlotError,
    showSecondSlotError,
    isFirstSlotChecked,
    isSecondSlotChecked,
    onFirstSlotCheckboxChange,
    onSecondSlotCheckboxChange
  } = props;

  const auth = useAuth();
  const { t } = useTranslationMGL(auth.authState.isLoggedIn ? 'Checkout-B2B' : 'Checkout');

  return (
    <Row id="tac_container" className="formContainer mt-5 tacSection_container">
      <Col>
        <Row>
          <h4 className="generalTermsAndConditions_header">{t(Keys.tac_header)}</h4>
        </Row>
        <Row>
          <Col>
            <Row className="mb-0 pb-0 ">
              <ScrollTo name={t(Keys.tac_firstSlot)} className="d-flex">
                <CustomInput
                  id={'generalTermsAndConditions_checkbox_one'}
                  style={{ zIndex: 0 }}
                  className="generalTermsAndConditions_customInput"
                  type="checkbox"
                  checked={isFirstSlotChecked}
                  onChange={onFirstSlotCheckboxChange}
                  onClick={e => e.stopPropagation()}
                />
                <h4
                  className="pb-0 mb-0 generalTermsAndConditions_item"
                  dangerouslySetInnerHTML={{ __html: sanitize(t(Keys.tac_firstSlot), true) }}
                />
              </ScrollTo>
            </Row>
            <Row>
              {showFirstSlotError && (
                <span className="generalTermsAndConditions_mandatoryFieldError">
                  {t(Keys.tac_mandatoryFieldError)}
                </span>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="mb-0 pb-0 ">
              <ScrollTo name={t(Keys.tac_secondSlot)} className="d-flex">
                <CustomInput
                  id={'generalTermsAndConditions_checkbox_two'}
                  style={{ zIndex: 0 }}
                  className="generalTermsAndConditions_customInput"
                  type="checkbox"
                  checked={isSecondSlotChecked}
                  onChange={onSecondSlotCheckboxChange}
                  onClick={e => e.stopPropagation()}
                />
                <h4
                  className="pb-0 mb-0 generalTermsAndConditions_item"
                  dangerouslySetInnerHTML={{ __html: sanitize(t(Keys.tac_secondSlot), true) }}
                />
              </ScrollTo>
            </Row>
            <Row>
              {showSecondSlotError && (
                <span className="generalTermsAndConditions_mandatoryFieldError">
                  {t(Keys.tac_mandatoryFieldError)}
                </span>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <h4
            className="generalTermsAndConditions_item"
            dangerouslySetInnerHTML={{ __html: sanitize(t(Keys.tac_thirdSlot), true) }}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default TACSection;
