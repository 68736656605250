/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2025-02-07 08:14:45 */

const Keys = {
  overview: 'overview',
  location: 'location',
  accommodation: 'accommodation',
  includedServices: 'includedServices',
  show_more: 'show_more',
  show_less: 'show_less'
}; export default Keys;